import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import NavBar from "./NavBar";
import Footer from "./Footer";
import MetaData from "./MetaDatas";

import "./Styles/Landpage.css";

export default function Landingpage() {
  useEffect(() => {
    //toast.info(
      /*"Welcome to SafelyShare! We are currently in testing and would be happy for your feedback",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }*/
    //);
  }, []);

  return (
    // <div>
    //   <NavBar></NavBar>
    //   <img className="bluebox1" src="/images/blue_design 1.png" alt="" />
    //   <div className="bluecont">
    //     <img
    //       className="bluecontbgimg"
    //       src="/images/bg_lego joints.png"
    //       alt=""
    //     />
        <>
       <div>
         <NavBar></NavBar>
         {/* <img className="bluebox1" src="/images/blue_design 1.png" alt="" /> */}
         <div className="bluecont">
         <img
      //       className="bluecontbgimg"
      //       src="/images/bg_lego joints.png"
      //       alt=""
          />
      <div className="frcont">Safelyshare</div><div className="frcont2">
        A secure platform to lend and borrow products
      </div>
      </div>
      <div className="butfrco">
        <Link className="ihms" to="/product">
          <img src="/images/frcont.png" alt="product" />
        </Link>
      </div>
      {/* <div>
        <img
          className="hmpgill"
          src="/images/home_page_illustration.png"
          alt=""
        />
      </div> */}
      <div className="co1">
        Safelyshare is a software platform designed to make product sharing
        easier and more accessible. By enabling users to lend and borrow items,
        Safelyshare helps reduce waste, keeps items out of landfills, and
        supports individuals in achieving their personal sustainability goals.
        The core focus of Safelyshare is promoting a circular economy, where
        resources are reused, shared, and circulated rather than discarded.
        <br></br>
        <br></br>
        <b>How It Works</b>
        <br></br>
<b>Register Easily:</b>  
Signing up for Safelyshare is simple—users can register using their existing Google or Facebook accounts. Once registered, you can list the items you’re willing to share or browse products shared by others.
<br></br>
<br></br>
<b>Borrow with Confidence: </b>
At Safelyshare, we prioritize security and trust. Both borrowers and lenders must verify their email and phone number before engaging in any transactions. This ensures that all users are genuine, creating a safe and reliable community for product sharing.
Before you can borrow an item, you'll be prompted to complete the verification process. Once verified, you can browse and borrow products listed by other users with confidence. For added peace of mind, Safelyshare offers optional insurance to cover any potential loss or damage to borrowed items. This gives both the borrower and the lender assurance that their products are protected during the rental period
<br></br>
<br></br>
<b>Flexible Rental Periods:</b>
Each product is available for a 14-day rental period, but rentals can be extended on a prorated basis, allowing flexibility to meet your needs.
<br></br>
<br></br>
<b>Shipping & Availability:</b>
Shipping costs are typically covered by the borrower, calculated based on actual shipping rates. For sustainability, all products are lent and borrowed within the same country, making it easier to share responsibly and sustainably.
<br></br>
<br></br>
<b>Why Choose Safelyshare</b>
<br></br>
<br></br>
<b>Save Money </b>
Borrow products instead of purchasing new ones, reducing costs for items you might only need temporarily.
<br></br>
<br></br>
<b>Promote Sustainability </b> 
By sharing items instead of buying, you help reduce waste, conserve resources, and lower your environmental footprint. Safelyshare supports your personal sustainability goals by giving you access to a platform built on reuse and sharing.
<br></br>
<br></br>
<b>Join the Community </b>
Safelyshare brings together people who care about sustainability and community sharing. Connect with like-minded individuals and help create a circular economy where resources are efficiently used and shared.
<br></br>
      </div>
      {/* <div className="ihms">
        <Link className="ihms" to="/register"> 
          <img src="/images/imhs.png" alt="Link to Register" />
        </Link>
        <img className="drawnlego4" src="/images/drawnlego4.png" alt="" />
      </div> */}
       <br></br>
      {/* <div className="drawnlegcol">
        <div className="drawn_leg">
          <img src="/images/drawn_leg.png" alt="" />
        </div>
        <div className="drawn_leg2">
          <img src="/images/drawn_lego2.png" alt="" />
        </div>
      </div> */}
      {/* <div className="few">Key Features</div>
      <br></br>
       <b>Easy Registration: </b>Sign up quickly using Google or Facebook.
       <br></br>
       <b>Secure Verification: </b> Email and phone verification to protect both lenders and borrowers.
       <br></br>
       <b>Optional Insurance:</b>  Add optional insurance to cover losses or damages during the rental period.
       <br></br>
       <b>Flexible Rental Periods: </b> Products are available for 14 days, with prorated extensions based on your needs.
       <br></br>
       <b>Local Sharing: </b> Products can be lent and borrowed within the same country to support sustainable, local sharing.
       <br></br>
       <b>People-Friendly Shipping: </b> Shipping costs are borne by the borrower, based on actual shipping rates.
       <br></br> */}
      {/* <img className="fboxcl" src="/images/fbox.png" alt="" /> */}
      {/* <img className="drawnlego3" src="/images/drawnlego3.png" alt="" />
      <div className="co2">
              </div>
      <img className="imhm" src="/images/imhmpa.png" alt="" /> */}
      <div className="bboxb">
        <div className="feattext">Mission</div>
        {/* <img className="whiline" src="/images/featuresbox.png" alt="" /> */}
        <div className="ccont123">
        At Safelyshare, we believe in the power of sharing to reduce waste and preserve resources. By encouraging product sharing, we support a circular economy—an economy where items are borrowed, used, and returned rather than discarded. Every shared product helps reduce production demands, minimize landfill waste, and slow down the cycle of overconsumption. Together, we can create a future where sustainability isn’t just a choice—it’s a way of life.
        </div>
      </div>
      <Footer></Footer>
    </div>
    </>
  );
}
